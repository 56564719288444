import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Email, Heart, Insta } from "../../assets/svg";
import { Colors } from "../../utils/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { UserContext } from "../Context";
import { getAllUsers } from "../../utils/getAllUsers";

const InnerDiv = styled.div`
  max-width: 692px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 66px;
  .form-select {
    line-height: 2.5 !important;
    margin-left: 20px !important;
    background-color: #f8f8f8;
    padding: 10px 24px;
    border: none !important;
    border-radius: 10px;
    &:focus {
      outline: none !important;
    }
  }
  input {
    background-color: #f8f8f8;
    border-radius: 10px;
    max-width: 500px;
    padding: 16px 24px;
    border: none;
    border-radius: 10px;
    outline: none;
    &:focus {
      outline: none;
      border: 1px solid ${Colors.primaryColor};
    }
  }
  select {
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 16px 24px;
    border: none;
    border-radius: 10px;
    &:focus {
      outline: none;
      border: 1px solid ${Colors.primaryColor};
    }
  }
  @media (max-width: 575px) {
    padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 12px;
    svg {
      width: 32px;
      height: 32px;
    }
    input {
      height: auto;
    }
    select {
      height: auto;
    }
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;
const ErrorMsg = styled.p`
  margin-top: 5px;
  font-size: 14px;
  color: red;
  margin-left: 35px;
  margin-bottom: 0;
`;
const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 40px;
  letter-spacing: 0.0125em;
  padding-bottom: 20px;
  color: #3d3d3d;
  @media (max-width: 575px) {
    padding: 0px 0;
    font-size: 27px;
  }
`;
const Hr = styled.hr`
  padding-bottom: 20px;
`;
const Button = styled.button`
  padding: 16px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  color: #ffffff;
  background-color: ${Colors.primaryColor};
  border-radius: 10px;
  border: none;
`;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("User Name is required"),
  email: Yup.string().required("Email is required"),
  like: Yup.string().required("like is required"),
});

const StepOne = ({ onclick, isLoading, posts }: any) => {
  const { price, selectCurrency, buyBtnPrice, userName } = useContext(
    UserContext
  ) as any;

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: userName,
    },
  };

  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;
  const onSubmission = (data: any) => {
    if (data.like.split(" ").at(-1) === "undefined") {
      const defaultData = {
        username: data.username,
        email: data.email,
        like: `${posts[0].quantity} ${posts[0].type} ${selectCurrency} ${buyBtnPrice === 0 ? posts[0].price : buyBtnPrice
          }`,
      };
      onclick(defaultData);
    } else {
      onclick(data);
    }
  };




  return (
    <div className="mb-5">

      <form onSubmit={handleSubmit(onSubmission)}>
        <div className="d-flex justify-content-center mt-5">
          <InnerDiv>
            <Heading className="text-center">Get Started</Heading>
            <Hr />
            <div className="d-flex align-items-baseline">
              <div>
                <Insta />
              </div>
              <ImageWrapper>
                <input
                  placeholder="Instagram username"
                  className="w-100 ms-3"
                  {...register("username")}
                />
                <ErrorMsg>{errors.username?.message as any}</ErrorMsg>
              </ImageWrapper>
            </div>
            <div className="d-flex align-items-baseline">
              <div>
                <Email />
              </div>
              <ImageWrapper>
                <input
                  placeholder="Email address"
                  className="w-100 ms-3"
                  {...register("email")}
                />
                <ErrorMsg>{errors.email?.message as any}</ErrorMsg>
              </ImageWrapper>
            </div>
            <div className="d-flex align-items-baseline">
              <div>
                <Heart />
              </div>
              <select
                style={{ cursor: "pointer" }}
                // className="w-100 ms-3"
                className="form-select w-100 ms-3" aria-label="Default select example"
                {...register("like")}
              >
                {posts &&
                  posts.map((item: any, index: number) => {
                    return (
                      <option
                        key={index}
                        selected={
                          index ===
                          posts.findIndex((a: any) => {
                            return a.price === price;
                          })
                        }
                      >
                        <span>{item.quantity}</span> {item.type}
                        <span> {selectCurrency} </span>
                        {item.price}
                      </option>
                    );
                  })}
              </select>
            </div>
            <p>{errors.title?.message as any}</p>
            <Button className="w-100" type="submit">
              {!isLoading ? "Continue" : "Loading..."}
            </Button>
          </InnerDiv>
        </div>
      </form>
    </div>
  );
};

export default StepOne;
