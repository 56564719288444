import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import InstaDetails from "../InstaDetails/InstaDetails";

const OuterDiv = styled.div`
  width: 100%;
  max-width: 911px;
  padding: 38px 28px;
  background: #f9fdff;
  border: 0.5px solid #e6e6e6;
  border-radius: 10px;
  @media (max-width: 991px) {
    max-width: 583px;
    width: 100%;
    padding: 14px;
  }
  @media (max-width: 575px) {
    max-width: 325px;
    width: 100%;
    padding: 14px 5px;
  }
`;

const InnerDiv = styled.div`
  max-width: 911px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 576px) and (max-width: 991px) {
    max-width: 575px;
  }
  @media (max-width: 575px) {
    max-width: 320px;
  }
`;
const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.0125em;
  color: #3d3d3d;
  border-bottom: 0.5px solid #c6c6c6;
  padding: 24px 0px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
const Image = styled.div`
  /* flex: 3; */
  cursor: pointer;
  padding: 0;
  input {
    height: 15px;
    top: 4%;
    right: 8%;
    cursor: pointer;
    border-radius: 50% !important;
  }
  @media (max-width: 575px) {
    padding: 0 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 15px;
  }
`;
const Img = styled.img<{ fade: boolean }>`
  width: 100%;
  max-width: 233px;
  height: 200px;

  ${({ fade }) =>
    fade
      ? css`
          opacity: 0.8;
          filter: grayscale(100%);
        `
      : null}

  @media (max-width: 575px) {
    height: 130px;
  }
`;
const ImagesWrapper = styled.div`
  padding: 15px 30px;
  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

const RadioPriceDiv = styled.div<{ checked: any }>`
  display: ${(props) => (props.checked ? "block" : "none")};
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  color: #fff;
  overflow: hidden;
  background: rgb(29, 161, 242);
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  padding: 4px 0;
`;

const StepTwo = ({ setSteps, state, setstate }: any) => {


  let divide = 2;
  const numberOfLikes = Number(state.service.split(" ")[0]);
  const [selected, setSelected] = useState<number[]>([]);
  const [count, setCount] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState(0);



  if (numberOfLikes === 50) {
    divide = 2;
  } else if (numberOfLikes === 100) {
    divide = 4;
  } else if (numberOfLikes === 250) {
    divide = 5;
  } else if (numberOfLikes === 500) {
    divide = 10;
  } else if (numberOfLikes === 1000) {
    divide = 10;
  } else if (numberOfLikes === 2500) {
    divide = 10;
  } else if (numberOfLikes === 5000) {
    divide = 10;
  } else if (numberOfLikes === 10000) {
    divide = 10;
  }

  const handleOnChange = (index: number) => {
    if (selected.includes(index)) {
      setSelected((old) => old.filter((item) => item != index));
    } else {
      if (selected.length < divide) {
        setSelected((old) => [...old, index]);
      } else {
        alert(
          `You may not select less than ${selected.length} likes for current plan.`
        );
      }
    }
  };

  const handleDivision = () => {
    const likes = state.service.split(" ")[0];
    if (selected.length > 1) {
      return Math.round(likes / selected.length);
    }
  };
  const selectedItems = selected.map((item) => {
    return {
      url: state.postsData.latestPosts[item].url,
      displayUrl: `${process.env.BACKEND_URL}/api/img/${encodeURIComponent(
        state.postsData.latestPosts[item].displayUrl
      )}`,
    };
  });

  useEffect(() => {
    if (count != 0) setValue(Math.floor(value / count));
  }, [count]);
  useEffect(() => {
    setstate((old: any) => {
      return {
        ...old,
        links: selectedItems,
        quantity:
          selected.length > 1 ? handleDivision() : state.service.split(" ")[0],
      };
    });
    if (selected.length > 0) setShow(true);
  }, [selected]);

  return (
    <div className=" pb-5">
      <div className="d-flex align-items-center flex-column">
        <OuterDiv className="my-3">
          <InstaDetails
            price={state.amount}
            text="continue checkout"
            value={selected.length}
            setSteps={setSteps}
            userData={state.postsData}
            style={{}}
            state={state}
          />
        </OuterDiv>
        <InnerDiv>
          <Heading className="text-center">Select Post</Heading>
          <ImagesWrapper className="row">
            {state.postsData.latestPosts.map((step: any, index: number) => {
              return (
                <div className="col-6 col-md-4 col-lg-3 p-2" key={index}>
                  <label className="position-relative w-100">
                    <Image>
                      <Img
                        src={`${
                          process.env.BACKEND_URL
                        }/api/img/${encodeURIComponent(step.displayUrl)}`}
                        alt="post img"
                        width={145}
                        height={145}
                        fade={step.type === "Image" && state.type === "view"}
                      />
                      <input
                        className="form-check-input position-absolute radioStyle"
                        type="checkbox"
                        checked={show && selected.includes(index)}
                        id={step.id}
                        onChange={() => handleOnChange(index)}
                        disabled={
                          step.type === "Image" && state.type === "view"
                        }
                      />
                      <RadioPriceDiv
                        className="w-100"
                        checked={show && selected.includes(index)}
                      >
                        <span className="">
                          {selected.length > 1
                            ? handleDivision()
                            : state.service.split(" ")[0]}
                        </span>
                        <span> {state.type}</span>
                        <br />
                      </RadioPriceDiv>
                    </Image>
                  </label>
                </div>
              );
            })}
          </ImagesWrapper>
        </InnerDiv>
      </div>
    </div>
  );
};
export default StepTwo;
