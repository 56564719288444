import axios from "axios";
import { toast } from "react-toastify";

export const createUser = async (userName: string,email:string,service:string) => {
  try {
    const userData = JSON.stringify({
      data: {
        userName,
        email,
        service
      },
    });

    var config = {
      method: "post",
      url: `${process.env.BACKEND_URL}/api/user-lists`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
      data: userData,
    };

    const { data } = await axios(config);
   return data
  } catch (error: any) {
    toast.error(error.message);
  }
};
