import React, { useContext, useEffect, useState } from "react";
import StepOne from "../components/Steps/StepOne";
import StepTwo from "../components/Steps/StepTwo";
import PaymentNavbar from "../components/PaymentNavbar/PaymentNavbar";
import { UserContext } from "../components/Context";
import { navigate } from "gatsby";
import Seo from "../components/Seo";
import ServiceListData from "../components/serviceListData";
import {  toast } from "react-toastify";
import axios from "axios";
import { getUser } from "../utils/getUser";
import { createUser } from "../utils/createUser";
import { getOffer } from "../utils/getOffer";
import confirmOrder from "../utils/confirmOrder";
import { createOrder } from "../utils/createOrder";

let myParam: string;
const Steps = () => {
  const {
    setResponse,
    count,
    posts,
    setPosts,
    selectCurrency,
    buyBtnPrice,

  } = useContext(UserContext) as any;

  const [steps, setSteps] = useState("FirstStep");
  const [isLoading, setIsLoading] = useState(false);
  const [activeBackground, setActiveBackground] = useState<boolean>(false);
  const [state, setState] = useState({
    email: "",
    username: "",
    service: "",
    serviceId: 0,
    amount: 0,
    postsData: {},
    links: [],
    totalQuantity: 0,
    quantity: 0,
    type: "",
    currencyType: selectCurrency,
  });

  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("service") as any;
  }

  const fetch = async () => {
    return await ServiceListData(myParam, selectCurrency);
  };
  useEffect(() => {
    fetch().then((res) => {
      setPosts(res);
    });
  }, [selectCurrency]);

  const freeOrder = async (offer: boolean) => {
    try {
      const orderData = {
        serviceId: state.serviceId,
        quantity: state.quantity,
        type: state.type,
        userName: state.username,
        offer,
        email: state.email,
        amount: state.amount,
      } as any;
      
      const checkout = await confirmOrder(orderData);
      if (checkout) {
        const storeOrderData = {
          orderId: `${checkout.data.orderId}`,
          amount: `${selectCurrency}${state.amount}`,
          post: state.links,
          type: `${state.type}`,
          quantity:`${state.quantity}`
        } as any;
        createOrder(storeOrderData, state.username);
        setResponse({storeOrderData,userName:state.username});
        navigate("/thankyou");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const fetchData = async () => {
    const offer = await getOffer(state.username);
    if (offer) {
      toast.info("free order subscribed");
      freeOrder(offer);
    } else {
      navigate("/checkout", { state: { state } });
    }
  };
  useEffect(() => {
    if (state != undefined && state.type === "follower") {
      fetchData();
    }
  }, [state]);

  const onclick = async (datas: any) => {


    const service_id =
      posts &&
      posts.filter(
        (item: any) => item.quantity === Number(datas.like.split(" ")[0])
      )[0]?.service;

    const type =
      posts &&
      posts.filter(
        (item: any) => item.quantity === Number(datas.like.split(" ")[0])
      )[0].type;
    setIsLoading(true);
    try {
      const { status } = (await getUser(datas.username)) as any;
      if (!status) {
        createUser(datas.username, datas.email, datas.like);
      }
      const config = {
        method: "GET",
        url: `${process.env.BACKEND_URL}/api/posts/${datas.username}`,
      };
      const { data } = await axios(config);

      if (data.status) {
        datas.posts = data.result;
        if (myParam !== "followers") {
          setState({
            postsData: data.result,
            email: datas.email,
            username: datas.username,
            service: datas.like,
            serviceId: service_id,
            links: [],
            totalQuantity: Number(datas.like.split(" ")[0]),
            amount:
              buyBtnPrice === 0
                ? Number(datas.like.split(" ")[3])
                : buyBtnPrice,
            quantity: 0,
            type: type,
            currencyType: selectCurrency,
          });
          setSteps("SecondStep");
        } else {
          setState({
            postsData: data.result,
            email: datas.email,
            username: datas.username,
            service: datas.like,
            serviceId: service_id,
            links: [],
            totalQuantity: Number(datas.like.split(" ")[0]),
            amount:
              buyBtnPrice === 0
                ? Number(datas.like.split(" ")[3])
                : buyBtnPrice,
            quantity: Number(datas.like.split(" ")[0]),
            type: type,
            currencyType: selectCurrency,
          });
        }
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateSteps = () => {
    switch (steps) {
      case "FirstStep":
        return (
          <>
            {posts && posts.length !== 0 ? (
              <StepOne
                onclick={onclick}
                isLoading={isLoading}
                posts={posts}
                // onSubmit={(data: any) => {
                //   return setActiveBackground(data.activeBackgroundColor);
                // }}
                // setSteps={setSteps}
                // setState={setState}
              />
            ) : null}
          </>
        );
      case "SecondStep":
        return (
          <StepTwo state={state} setstate={setState} setSteps={setSteps} />
        );
    }
  };

  return (
    <>

      <Seo title="Buy Service" description="Buy Instagram Service page" />
      <div className="overflow-hidden">
        <PaymentNavbar steps={steps} />
        {navigateSteps()}
      </div>
    </>
  );
};

export default Steps;