
export  const Colors={
    primaryColor:'#1DA1F2',
    primaryText: '#3D3D3D',
    purple: '#611DA7',
    purpleLight: '#DCBAFF',
    yellowLight: '#FFED99',
    brownLight: '#807857',
    redLight: '#FF5721',
    tomatoLight:'#FFE2D9',
    
}