import { getAllUsers } from "./getAllUsers";
import { getUser } from "./getUser";

export const getOffer = async (userName: string) => {
  const { data } = await getAllUsers();
  const { user } = (await getUser(userName)) as any;  
  if ((data.length < 100) && (user.orders.data.length === 0)) {
    return true;
  }
  return false;
};

