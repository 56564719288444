import axios from "axios";
import { toast } from "react-toastify";

export const getAllUsers = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.BACKEND_URL}/api/user-lists?populate=orders`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios(config);   
  return data
    
  } catch (error: any) {
    toast.error(error.message);
  }
};