import { navigate } from "gatsby";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { confirmOrderProps, props } from "../../interface";
import { Colors } from "../../utils/colors";
import { UserContext } from "../Context";
import { getUser } from "../../utils/getUser";
import { toast } from "react-toastify";
import { getOffer } from "../../utils/getOffer";
import axios from "axios";
import { createOrder } from "../../utils/createOrder";
import confirmOrder from "../../utils/confirmOrder";

const Checkout = styled.button`
  padding: 14px 24px;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  background-color: ${Colors.primaryColor};
  border: none;
  border-radius: 10px;
  @media (max-width: 767px) {
    max-width: 253px;
    width: 100%;
    height: 56px;
    font-size: 14px;
  }
`;
const PersonImg = styled.div`
  img {
    border-radius: 50%;
  }
  align-items: center;
  @media (max-width: 767px) {
    padding-left: 17px;
    svg {
      width: 48px;
      height: 48px;
    }
  }
  @media (max-width: 575px) {
    justify-content: center;
    padding-left: 0px;
  }
`;
const PersonName = styled.div`
  @media (max-width: 767px) {
    span {
      padding-top: 5px !important;
    }
  }
`;
const CheckoutDiv = styled.div`
  @media (max-width: 767px) {
    margin: 14px 0px;
    padding: 0px 18px !important;
  }
`;
const PersonDiv = styled.div`
  @media (max-width: 575px) {
    text-align: center;
  }
`;
const UserName = styled.span`
  font-family: "Poppins";
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #676767;
`;
const InstaDetails = ({
  text,
  style,
  value,
  price,
  userData,
  state,
  from
}: props) => {
  const { setResponse, selectCurrency, count, buyBtnPrice } = useContext(
    UserContext
  ) as any;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const paidOrder = () => {
    setIsLoading(false);
    if (state.links.length !== 0) {
      if (state.type !== "follower") {
        navigate("/checkout", { state: { state } });
        setIsLoading(false);
      }
    } else {
      alert("Please select post");
      setIsLoading(false);
    }
  };
  const freeOrder = async (offer: boolean) => {

   
    try {
      const orderData = {
        link: state.links,
        serviceId: state.serviceId,
        quantity: state.quantity,
        type: state.type,
        userName: state.username,
        offer,
        email: state.email,
        amount: state.amount,
      } as any;
      const checkout = await confirmOrder(orderData);
      if (checkout) {
        const storeOrderData = {
          orderId: `${checkout.data.orderId}`,
          amount: `${selectCurrency}${state.amount}`,
          post: state.links,
          type: `${state.type}`,
          quantity:`${state.quantity}`
        };
        createOrder(storeOrderData, state.username);
        setResponse({storeOrderData,userName:state.username});
        navigate("/thankyou");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const checkout = async () => {
    try {
      const offer = await getOffer(state.username);
    
      setIsLoading(true);
      if (offer || from === "freeOrder") {
        toast.info("free order subscribed");
        freeOrder(offer);
      } else {
        paidOrder();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isCheckoutPage = text === "total to pay";

  return (
    <PersonDiv
      className={`${
        isCheckoutPage ? "d-block" : "d-block d-sm-flex"
      } justify-content-between align-items-center`}
    >
      <PersonImg className="d-flex">
        <PersonName
          className={`${isCheckoutPage ? "ps-0" : "ps-4"} d-flex flex-column`}
        >
          <UserName>{userData.userName}</UserName>
          <span className="pt-2">
            <span style={{ color: Colors.primaryColor }}>{value}</span> posts
            selected
          </span>
        </PersonName>
      </PersonImg>
      <CheckoutDiv className={`${isCheckoutPage ? "ps-0 mt-3" : "ps-3"}`}>
        <Checkout
          onClick={() => {

         
            !isCheckoutPage && checkout();
          }}
          disabled={isCheckoutPage}
          style={style}
        >
          {isLoading ? (
            <>Loading...</>
          ) : (
            <>
              {text} | {selectCurrency}
              {price}
            </>
          )}
        </Checkout>
      </CheckoutDiv>
    </PersonDiv>
  );
};

export default InstaDetails;
